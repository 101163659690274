import axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import VueSlider from 'vue-slider-component';

// Mixing MV* and vanilla javascript like the bad asses we are
const PRODUCT_INDEX_SELECTOR = '#product-index';

// The @Component decorator indicates the class is a Vue component
@Component({
  props: {
    postUrl: {
      type: String,
      required: true
    }
  },
  components: {
    VueSlider
  }
})
export default class ProductFilter extends Vue {
  public form: FilterQuery = {};
  public postUrl: string | undefined;

  public mounted(): void {
    this.deserializeQueryString();

    // Registering this in the component options might cause the above line to
    // trigger a query
    this.$watch('form', this.updateResults, { deep: true });
  }

  /**
   * Perform a search and overwrites the search results.
   */
  public updateResults(form: FilterQuery, oldForm: FilterQuery): void {
    const queryString = this.encodeQueryString();

    axios.get(<string>this.postUrl, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      params: queryString
    }).then(function(response) {
      document.querySelector(PRODUCT_INDEX_SELECTOR)!.outerHTML = response.data;

      window.history.replaceState(undefined, undefined, '?' + queryString.toString());
    });
  }

  /**
   * Clicking a checked radio button (switch) should remvoe the current choice
   * (or 'uncheck' the radio button).
   */
  public cancelRadioButton(event: any) {
    // This should be applied on the label instead, as this otherwise breaks
    // with iOS's onClick delays
    if (this.form.type == event.target.control.value) {
      this.$set(this.form, 'type', undefined);
    }
  }

  // These are the min and max values for light intensity and price. Vue doesn't
  // handle array literals, and there are no references or pointer types for
  // numbers in JavaScript, so we use these dummy values and two functions to
  // sync htese back up to `this.form`.
  public lumens = [0, 35_000];
  public price = [0, 20_000];

  public updateLumens([min, max]: number[]): void {
    if (min !== this.lumens[0]) {
      this.$set(this.form, 'lumens_min', min);
      this.lumens[0] = min;
    }

    if (max !== this.lumens[1]) {
      this.$set(this.form, 'lumens_max', max);
      this.lumens[1] = max;
    }
  }

  public updatePrice([min, max]: number[]): void {
    if (min !== this.price[0]) {
      this.$set(this.form, 'price_min', min);
      this.price[0] = min;
    }

    if (max !== this.price[1]) {
      this.$set(this.form, 'price_max', max);
      this.price[1] = max;
    }
  }

  /**
   * Decode the pages current query string and use it to set the initial form
   * values.
   */
  private deserializeQueryString(): void {
    const urlParams = new URLSearchParams(window.location.search);

    for (const key of urlParams.keys()) {
      const value = urlParams.get(key);

      if (value === 'True') {
        this.$set(this.form, key, true);
      } else if (!isNaN(Number(value))) {
        this.$set(this.form, key, Number(value));
      } else {
        this.$set(this.form, key, value);
      }
    }

    // Make sure the sliders have updated, as they require us to have a separate
    // object for their initial state
    this.lumens = [this.form.lumens_min || this.lumens[0], this.form.lumens_max || this.lumens[1]];
    this.price = [this.form.price_min || this.price[0], this.form.price_max || this.price[1]];
  }

  /**
   * Create a query string based on the form.
   */
  private encodeQueryString(): URLSearchParams {
    const values = Object.keys(this.form)
      .map((key) => [key, this.form[key]])
      .filter(([_, value]) => value !== undefined && value !== false)
      .map(([key, value]) => [key, value === true ? 'True' : String(value)])

    const queryString = new URLSearchParams();
    for (const pair of values) {
      queryString.set(pair[0], pair[1]);
    }

    return queryString;
  }
}

interface FilterQuery extends Object {
  type?: string;
  has_neutral_led?: boolean;
  has_warm_led?: boolean;
  has_rgb_led?: boolean;

  lumens_min?: number;
  lumens_max?: number;
  price_min?: number;
  price_max?: number;

  [key: string]: any;
}
