import * as $ from 'jquery';
import 'foundation-sites';

import Vue from 'vue';

import './styles/app.scss';
import ProductFilter from './components/ProductFilter.vue';

$(document).foundation();

new Vue({
  el: '#app',
  components: {
    'product-filter': ProductFilter
  }
});
